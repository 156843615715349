class Paintings {
  constructor(id, description, imageUrl, cat, insta) {
    this.id = id;
    this.description = description;
    this.imageUrl = imageUrl;
    this.cat = cat;
    this.insta = insta;
  }
}

export default Paintings;
