import { PAINTINGS } from '../../data/paintings-data';
import { SET_FILTERS } from '../actions/painting';

const initialState = {
  painting: PAINTINGS,
  filteredPainting: PAINTINGS
};

const paintingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      const appliedFilters = action.filters;
      const updatedFilteredPainting = state.painting.filter((painting) => {
        if (appliedFilters.which === 'all') {
          return true;
        } else if (
          appliedFilters.which === 'latest' &&
          painting.cat.includes('latest')
        ) {
          return true;
        } else if (
          appliedFilters.which === 'animal' &&
          painting.cat.includes('animal')
        ) {
          return true;
        } else if (
          appliedFilters.which === 'land' &&
          painting.cat.includes('land')
        ) {
          return true;
        } else if (
          appliedFilters.which === 'water' &&
          painting.cat.includes('water')
        ) {
          return true;
        } else if (
          appliedFilters.which === 'floral' &&
          painting.cat.includes('floral')
        ) {
          return true;
        } else if (appliedFilters.which === 'veg' && painting.cat === 'veg') {
          return true;
        } else if (
          appliedFilters.which === 'build' &&
          painting.cat.includes('build')
        ) {
          return true;
        } else if (
          appliedFilters.which === 'other' &&
          painting.cat === 'other'
        ) {
          return true;
        }
      });
      return { ...state, filteredPainting: updatedFilteredPainting };
    default:
      return state;
  }
};

export default paintingReducer;
