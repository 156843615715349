import React, { useState } from 'react';
import { SocialIcon } from 'react-social-icons';

import './Card.css';

const Card = (props) => {
  //variable and calculations for positions
  const imgNum = Math.floor(Math.random() * 18) + 1;
  const imgURL = `/images/tapes/${imgNum}.png`;

  const plusOrMinus = () => (Math.random() < 0.5 ? -1 : 1);
  const rotateNum = Math.floor(Math.random() * 5) * plusOrMinus();

  const leftNum = Math.floor(Math.random() * 26) * plusOrMinus();

  const tapeStyle = {
    transform: `rotate(${rotateNum}deg) scaleX(${plusOrMinus()}) scaleY(${plusOrMinus()})`,
    left: `${leftNum}px`
  };

  const cardRotate = Math.floor(Math.random() * 4) * plusOrMinus();

  const cardStyle = {
    transform: `rotate(${cardRotate}deg)`,
    position: 'relative',
    zIndex: '0'
  };

  //position state
  const [positionState] = useState({
    position: [{ tapeImg: imgURL, tape: tapeStyle, card: cardStyle }]
  });

  //Img Modal
  const [imgOpen, setImgOpen] = useState(false);
  const modal = document.querySelector('.modal');
  const original = document.querySelector('.full-img');
  const bodyModal = document.querySelector('body');

  const imageHandler = (e) => {
    setImgOpen(!imgOpen);
    console.log('clicked', imgOpen);
    console.log(e.target.getAttribute('src'));
    bodyModal.classList.add('open');
    modal.classList.add('open');
    original.classList.add('open');
    original.src = `${e.target.getAttribute('src')}`;
  };

  let Insta;

  if (props.insta) {
    Insta = (
      <SocialIcon
        className='instaIcon'
        url={props.insta}
        style={{ height: 30, width: 30, position: 'absolute' }}
        target='_blank'
        rel='noopener noreferrer'
      />
    );
  }

  return (
    <div className='whole-card'>
      <div className='tape' style={positionState.position[0].tape}>
        <img
          className='tape-img'
          src={positionState.position[0].tapeImg}
          alt='a taped effect'
        />
      </div>
      <div className='Card' style={positionState.position[0].card}>
        <div className='Card-img'>
          <img
            className='image'
            src={props.url}
            alt='a watercolour'
            onClick={imageHandler}
          />
        </div>
        <div className='Card-des'>{props.des}</div>
        {Insta}
      </div>
    </div>
  );
};

export default Card;
