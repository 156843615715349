import React, { useState, useEffect, useCallback } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector, useDispatch } from 'react-redux';

import { setFilters } from '../store/actions/painting';

import '../App.css';
import Header from '../components/Header/Header';

import Card from '../components/Card/Card';

const Home = (props) => {
  const [isWhich, setWhich] = useState('latest');
  const dispatch = useDispatch();
  const availablePort = useSelector((state) => state.painting.filteredPainting);

  const saveFilters = useCallback(() => {
    const appliedFilters = {
      which: isWhich
    };

    dispatch(setFilters(appliedFilters));
  }, [isWhich, dispatch]);

  useEffect(() => {
    saveFilters();
  }, [saveFilters]);

  const whichClickHandler = (e) => {
    let menu = document.querySelector('.active-filter');
    menu.classList.toggle('active-filter');
    let element = document.getElementById(e);
    element.classList.add('active-filter');
    setWhich(e);
    saveFilters();
  };

  let modal;
  let original;
  let bodyModal;
  const modalClickHandler = (e) => {
    modal = document.querySelector('.modal');
    original = document.querySelector('.full-img');
    bodyModal = document.querySelector('body');

    if (e.target.classList.contains('modal')) {
      modal.classList.remove('open');
      original.classList.remove('open');
      bodyModal.classList.remove('open');
    }
  };

  const all = 'all';
  const latest = 'latest';
  const animal = 'animal';
  const land = 'land';
  const water = 'water';
  const floral = 'floral';
  const veg = 'veg';
  const build = 'build';
  const other = 'other';

  return (
    <div>
      <Header />
      <main>
        <div className='port-buttons'>
          <div
            id='latest'
            className='active-filter'
            value={latest}
            onClick={() => whichClickHandler(latest)}
          >
            Latest
          </div>
          <div id='all' value={all} onClick={() => whichClickHandler(all)}>
            All
          </div>
          <div
            id='animal'
            value={animal}
            onClick={() => whichClickHandler(animal)}
          >
            Animals
          </div>
          <div id='land' value={land} onClick={() => whichClickHandler(land)}>
            Landscape/Trees
          </div>
          <div
            id='water'
            value={water}
            onClick={() => whichClickHandler(water)}
          >
            Water
          </div>
          <div
            id='floral'
            value={floral}
            onClick={() => whichClickHandler(floral)}
          >
            Floral
          </div>
          <div id='veg' value={veg} onClick={() => whichClickHandler(veg)}>
            Vegetables and Fruits
          </div>
          <div
            id='build'
            value={build}
            onClick={() => whichClickHandler(build)}
          >
            Buildings
          </div>
          <div
            id='other'
            value={other}
            onClick={() => whichClickHandler(other)}
          >
            Other
          </div>
        </div>
        <div className='paint-all'>
          {availablePort.map((item, index) => (
            <LazyLoad key={index} height={100} offset={[-100, 100]}>
              <Card
                url={item.imageUrl}
                des={item.description}
                insta={item.insta}
                key={index}
              />
            </LazyLoad>
          ))}
        </div>
        <div className='modal' onClick={modalClickHandler}>
          <img src='' alt='' className='full-img' />
        </div>
      </main>
    </div>
  );
};

export default Home;
