import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import './App.css';
import Home from './components/Home';

import paintingReducer from './store/reducers/painting';

const rootReducer = combineReducers({
  painting: paintingReducer
});

const store = createStore(rootReducer);
// const store = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className='App'>
          <main className='main'>
            <Switch>
              <Route path='/' component={Home} />
            </Switch>
          </main>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
