import Paintings from '../models/paintings';

// land, water, floral, veg, build, other

export const PAINTINGS = [
  new Paintings(
    'p1',
    'One of his first attempts, made a Cornflower from an online tutorial',
    '/images/paintings/cornflower.png',
    'floral',
    'https://www.instagram.com/p/CBVUNtchZuF/'
  ),
  new Paintings(
    'p2',
    'Attempting lupins (inspired by our garden), using watercolour pencils',
    '/images/paintings/lupin.png',
    'floral',
    'https://www.instagram.com/p/CBX6wjZp-HU/'
  ),
  new Paintings(
    'p3',
    'Gave the cornflower a second try',
    '/images/paintings/cornflower2.png',
    'floral',
    'https://www.instagram.com/p/CBatRZlJYAY/'
  ),
  new Paintings(
    'p4',
    'Acorns, part of his early flora period. Practicing his pencil technique',
    '/images/paintings/acorn.png',
    'floral',
    'https://www.instagram.com/p/CBc14yKJxAd/'
  ),
  new Paintings(
    'p6',
    'Second attempt at radishes, a bit chubby',
    '/images/paintings/radishes2.png',
    'veg',
    'https://www.instagram.com/p/CBfcjAshWMZ/'
  ),
  new Paintings(
    'p7',
    'Leek his favorite vegetable, part of his vegetable series',
    '/images/paintings/leek.png',
    'veg',
    'https://www.instagram.com/p/CBilgUBpH5l/'
  ),
  new Paintings(
    'p8',
    'Nasturtiums his favorite flowers, here he is working on his pencil blending technique',
    '/images/paintings/nasturtium.png',
    'floral',
    'https://www.instagram.com/p/CBktUzbJ5br/'
  ),
  new Paintings(
    'p9',
    "Our 4th wedding anniversary was coming up.  This artistic phase didn't last too long, as it is obvious why",
    '/images/paintings/starTrek.png',
    'other',
    'https://www.instagram.com/p/CBnv-02JLcy/'
  ),
  new Paintings(
    'p11',
    'Our front porch, he loves his flowers',
    '/images/paintings/frontporch.png',
    'build, floral',
    'https://www.instagram.com/p/CBp8vOsJSzd/'
  ),
  new Paintings(
    'p12',
    'Here we enter the tree phase.  Willow trees remind him of his grandma',
    '/images/paintings/willowtree.png',
    'land',
    'https://www.instagram.com/p/CB0h9vypP4y/'
  ),
  new Paintings(
    'p13',
    'Inspiration the banana tree in our back yard, cute inspiration',
    '/images/paintings/bananaTree.png',
    'land',
    'https://www.instagram.com/p/CB2-LHPp4iV/'
  ),
  new Paintings(
    'p14',
    'Practicing trees',
    '/images/paintings/treePractice.png',
    'land'
  ),
  new Paintings(
    'p15',
    'From this point he started using tape, which gives the paintings nice clean edges',
    '/images/paintings/blueforest.png',
    'land'
  ),
  new Paintings(
    'p16',
    'Second attempt at the Banana tree',
    '/images/paintings/banana2.png',
    'land'
  ),
  new Paintings(
    'p18',
    'Started practicing sky and landscapes, this is from a tutorial',
    '/images/paintings/windmills.png',
    'land'
  ),
  new Paintings(
    'p19',
    'He used a tutorial by Liron Yanconsky on youtube.',
    '/images/paintings/treestutorial.png',
    'land, latest',
    'https://www.instagram.com/p/CBvlqsbJGkC/'
  ),
  new Paintings(
    'p20',
    'A swan, following a Liron Yanconsky tutorial on youtube.',
    '/images/paintings/swan.png',
    'water, latest, animal'
  ),
  new Paintings(
    'p21',
    'Venturing without a tutorial and painting from a photo of Razvan',
    '/images/paintings/razvan.png',
    'land, latest, animal'
  ),
  new Paintings(
    'p22',
    'This painting is inspired by a photo from our camping trips to Bryher in the Scilly Isles',
    '/images/paintings/bryherboat.png',
    'water, latest',
    'https://www.instagram.com/p/CBxxhuwJNKG/'
  ),
  new Paintings(
    'p23',
    'This is one of our all time favorite, inspired by a picture take by a friend at Abney Park',
    '/images/paintings/abnypark.png',
    'land, latest',
    'https://www.instagram.com/p/CBsPXt8J7Gu/'
  ),
  new Paintings(
    'p24',
    "This was inspired by a friend's garden.",
    '/images/paintings/backyard.png',
    'land,latest',
    'https://www.instagram.com/p/CB5tLVOJUqb/'
  ),
  new Paintings(
    'p25',
    'A building in our neighborhood. I like how he is adding different elements to his paintings.',
    '/images/paintings/street.png',
    'land,latest',
    'https://www.instagram.com/p/CB8je9cJjlK/'
  )
];
