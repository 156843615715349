import React from 'react';
import { SocialIcon } from 'react-social-icons';

import './Header.css';

const Header = (props) => {
  return (
    <div className='Header'>
      <div className='title'>HUBBY COLOURS</div>
      <ul>
        <li>
          <SocialIcon
            url='https://twitter.com/hubbycolours'
            style={{ height: 30, width: 30 }}
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
        <li>
          <SocialIcon
            url='https://instagram.com/hubbycolours'
            style={{ height: 30, width: 30 }}
            target='_blank'
            rel='noopener noreferrer'
          />
        </li>
      </ul>
    </div>
  );
};

export default Header;
